@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', system-ui, sans-serif;
  }
}

html {
  @media only screen and (max-height: 768px) {
    font-size: 11px;
  }

  @media only screen and (min-height: 768px) {
    font-size: 12px;
  }

  @media only screen and (min-height: 900px) {
    font-size: 14px;
  }

  @media only screen and (min-height: 1200px) {
    font-size: 16px;
  }
}

@layer components {
  .shaded-red {
    background-color: theme('colors.red.100');
    color: theme('colors.red.700');
    border-color: theme('colors.red.700');
  }
  .shaded-orange {
    background-color: theme('colors.orange.100');
    color: theme('colors.orange.700');
    border-color: theme('colors.orange.700');
  }
  .shaded-green {
    background-color: theme('colors.green.100');
    color: theme('colors.green.700');
    border-color: theme('colors.green.700');
  }
  .shaded-pink {
    background-color: theme('colors.pink.100');
    color: theme('colors.pink.700');
    border-color: theme('colors.pink.700');
  }
  .shaded-violet {
    background-color: theme('colors.violet.100');
    color: theme('colors.violet.700');
    border-color: theme('colors.violet.700');
  }
  .shaded-blue {
    background-color: theme('colors.blue.100');
    color: theme('colors.blue.700');
    border-color: theme('colors.blue.700');
  }
  .shaded-gray {
    background-color: theme('colors.gray.100');
    color: theme('colors.gray.700');
    border-color: theme('colors.gray.700');
  }
  .shaded-black {
    background-color: theme('colors.gray.800');
    color: theme('colors.gray.50');
    border-color: theme('colors.gray.800');
  }
  .shaded-indigo {
    background-color: theme('colors.indigo.600');
    color: theme('colors.indigo.50');
    border-color: theme('colors.indigo.600');
  }
  .striped-gray-bg {
    background: repeating-linear-gradient(
      -45deg,
      theme('colors.gray.50'),
      theme('colors.gray.50') 0.5em,
      theme('colors.gray.100') 0.5em,
      theme('colors.gray.100') 1em
    );
  }
}

@keyframes fadeOut {
  0% {
    background-color: rgba(254, 243, 199, 1);
  }
  50% {
    background-color: rgba(254, 243, 199, 1);
  }
  75% {
    background-color: rgba(255, 251, 235, 1);
  }
  100% {
    background-color: transparent;
  }
}

@layer utilities {
  .flow-col-max-full {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: stretch;
    width: 100%;
    max-width: 100%;
  }

  .flex-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }

  .flex-center {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}

.environment-staging.bg-cue {
  background-color: white;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(
    45deg,
    rgba(50, 50, 50, 0.1) 0,
    rgba(50, 50, 50, 0.1) 1px,
    white 0,
    white 50%
  );
}

.environment-staging.bg-brand {
  background-color: #cc6c5c;
}

.new-appointment {
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-timing-function: ease;
}

:where([data-headlessui-state~='selected']) .listbox-holder .ui-selected\:visible {
  visibility: hidden;
}

.listbox-holder :where([data-headlessui-state~='selected']) .ui-selected\:visible {
  visibility: visible;
}

:where([data-headlessui-state~='selected']) .listbox-holder .ui-selected\:font-semibold {
  font-weight: 400;
}

.listbox-holder :where([data-headlessui-state~='selected']) .ui-selected\:font-semibold {
  font-weight: 600;
}
